<template>
  <tr :id="mobileRow">
    <td><label>{{ mobile.mobile }}</label></td>
    <td>
      <modal-btn btn-class="btn-sm" :disabled="primary" :target="`#set-primary${mobile.id}`">
        <small class="link" :class="{disabled: primary}">{{ primaryText }}</small>
      </modal-btn>
    </td>
    <td>
      <modal-btn :target="`#${editModalId}`" btn-class="btn pm-0" title="تعديل هاتف">
        <i class="link fas fa-pen"></i>
      </modal-btn>
      <modal-btn :target="`#${deleteModalId}`" btn-class="btn pm-0" title="حذف هاتف">
        <i class="text-danger fa fa-trash-alt"></i>
      </modal-btn>
      <teleport to="#app">
        <confirm-model
          title="تعديل هاتف"
          :target-id="editModalId"
          confirm-text="تعديل"
          @confirmed="clickById(submitId)">
          <form @submit.prevent="update">
            <small class="link">
              <abbr :title="validMobilePattern">يرجى التعديل برقم هاتف صالح</abbr>
            </small>
            <form-input :id="inputId" name="mobile" :value="mobile.mobile"></form-input>
            <button type="submit" hidden :id="submitId"></button>
          </form>
        </confirm-model>
        <items-destroyer
          :target-id="deleteModalId"
          :url="`/mobiles/${mobile.id}`"
          title="حذف هاتف"
          :row-id="mobileRow" @deleted="mobileDeleted">
          <span>هل أنت متاكد من حذف رقم الهاتف</span>&nbsp;
          <span class="link">{{ mobile.mobile }}</span>؟
        </items-destroyer>
        <confirm-model
          v-if="!primary"
          :target-id="`set-primary${mobile.id}`"
          title="تعيين رقم رئيسي"
          @confirmed="setPrimary">
          <p class="pm-0">
            <span>هل أنت متأكد من تعيين الرقم</span>
            <span class="link">&nbsp;{{ mobile.mobile }}&nbsp;</span>
            <span>رقماً رئيسياً ؟</span>
          </p>
          <small class="link">إذا تم التأكيد سوف يتم الغاء الأرقام الرئيسية الأخرى في حال كانت موجودة</small>
        </confirm-model>
      </teleport>
    </td>
  </tr>
</template>

<script>
import ModalBtn from "@/components/UI/ModalBtn";
import ItemsDestroyer from "@/components/Common/ItemsDestroyer";
import ConfirmModel from "@/components/UI/ConfirmModel";
import FormInput from "@/components/Forms/FormInput";
import types from "@/common/types";

export default {
  components: {FormInput, ConfirmModel, ItemsDestroyer, ModalBtn},
  emits: ['updated', 'deleted', 'set-primary'],
  props: {
    mobile: {required: true, type: Object}
  },
  computed: {
    primary() {
      return !!this.mobile && types.checkY(this.mobile.primary);
    },
    primaryText() {
      return this.primary ? '(رئيسي)' : '(غير رئيسي)';
    },
    mobileRow() {
      return `mobile-row-${this.mobile.id}`;
    },
    inputId() {
      return `mobile${this.mobile.id}`;
    },
    editModalId() {
      return `edit-mobile-${this.mobile.id}`;
    },
    deleteModalId() {
      return `delete-mobile-${this.mobile.id}`;
    },
    submitId() {
      return `mobile-submit-${this.mobile.id}`;
    },
    validMobilePattern() {
      return messages.validMobilePattern;
    }
  },
  methods: {
    async update(e) {
      if (!this.mobile || !this.mobile.id) return console.error('empty mobile or mobile id');
      // TO PRINT ERROR UNDER SPECIFIC INPUT
      http.inputsId = this.mobile.id;
      // GET THE DATA OF FORM
      const formData = new FormData(e.target);
      formData.set('_method', 'PUT');
      // SEND REQUEST
      const response = await http.send(`mobiles/${this.mobile.id}`, formData);
      if (http.responseAccepted(response)) {
        // EMIT THE NEW MOBILE DATA TO THE MOBILES LIST
        this.$emit('updated', response.data.mobile);
        // HIDE THE MODAL OF UPDATING
        this.hideModal(`#${this.editModalId}`);
      }
      http.inputsId = '';
    },
    async setPrimary() {
      await this.$emit('set-primary', this.mobile.id);
      this.hideModal(`#set-primary${this.mobile.id}`);
    },
    mobileDeleted(id) {
      this.$emit('deleted', id);
    }
  }
}
</script>