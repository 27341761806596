<template>
  <div class="row" v-if="assistantId">
    <div class="col-md-8 pr-1">
      <base-card header="bg-soft-danger">
        <template #header>بياناتي</template>
        <template #body>
          <form @submit.prevent="update" class="container">
            <div class="row">
              <div class="col-md-3"><label>الإسم الرباعي</label></div>
              <div class="col-md-9">
                <form-input class="bg-transparent" id="name" :value="!!user ? user.name: ''"></form-input>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3"><label>إسم المستخدم</label></div>
              <div class="col-md-9">
                <form-input class="bg-transparent" id="username" :value="!!user ? user.username: ''"></form-input>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3"><label>البريد الإلكتروني</label></div>
              <div class="col-md-9">
                <form-input class="bg-transparent" id="email" :value="!!user ? user.email: ''"></form-input>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3"><label>كلمة المرور</label></div>
              <div class="col-md-9">
                <FormInput
                  id="password"
                  type="password"
                  title="كلمة المرور"
                  placeholder="كلمة المرور"
                  class="bg-transparent"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <button class="float-left btn btn-primary rounded-0">حفظ</button>
              </div>
            </div>
          </form>
        </template>
      </base-card>
    </div>
    <div class="col-md-4 pl-1">
      <base-card header="bg-soft-danger" body="pt-0">
        <template #header>أرقام الهواتف</template>
        <template #body>
          <mobiles v-if="showMobiles" :user-id="assistantId"></mobiles>
          <p v-else class="link" @click="setShowMobiles">عرض تفاصيل أرقام الهاتف</p>
        </template>
      </base-card>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 p-0">
      <base-card header="bg-soft-danger">
        <template #header>الصلاحيات</template>
        <template #body>
          <permissions :assistant-id="assistantId" v-if="showPermissions"></permissions>
          <label v-else class="link danger" @click="setShowPermissions">عرض الصلاحيات</label>
        </template>
      </base-card>
    </div>
  </div>
</template>

<script>
import Storage from "@/common/Storage";
import Mobiles from "@/components/Users/Mobile/Mobiles";
import BaseCard from "@/components/UI/BaseCard";
import FormInput from "@/components/Forms/FormInput";
import Permissions from "@/components/Assistants/Permissions";
import types from "@/common/types";
import messages from "@/common/messages";

export default {
  components: {Permissions, FormInput, BaseCard, Mobiles},
  data() {
    return {
      user: '',
      showMobiles: false,
      showPermissions: false,

    };
  },
  computed: {
    assistantId() {
      return this.$route.params.user;
    },
    storageName() {
      return `assistant-edit-${this.assistantId}`;
    }
  },
  methods: {
    setShowMobiles() {
      this.showMobiles = true;
    },
    setShowPermissions() {
      this.showPermissions = true;
    },
    async update(e) {
      if (!this.assistantId)
        return false;
      const formData = new FormData(e.target);
      formData.set('_method', 'PUT');
      const response = await http.send(`assistants/${this.assistantId}`, formData);
      if (http.responseAccepted(response)) {
        this.user = response.data.user;
        Storage.set(this.storageName, this.user, 3); // commented
        http.popupMessage('success', messages.saved)
      }
    },
    async setUser() {
      if (!this.assistantId)
        return false;
      const storedAssistant = !!this.storageName ? Storage.get(this.storageName) : '';
      if (types.isObject(storedAssistant)) {
        this.user = storedAssistant.data;
      } else {
        Storage.removeStartWith('assistant-edit-');
        const response = await http.send(`assistants/${this.assistantId}`, {_method: 'GET'});
        if (http.responseAccepted(response))
          this.user = response.data.user;
      }
      if (types.isObject(this.user))
        Storage.set(this.storageName, this.user, 3); // commented
    },
  },
  async created() {
    await this.setUser();
  }
}
</script>