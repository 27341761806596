<template>
  <div class="table-responsive" v-if="hasPermissions">
    <table class="table table-bordered">
      <tr>
        <th>الصلاحية</th>
        <th>الحالة</th>
      </tr>
      <PermissionRow v-for="permission in permissions" :key="permission.id" :permission="permission" @selected="selectPermission" />
    </table>
    <teleport to="#app">
      <confirm-model
        title="تعديل صلاحية المساعد"
        target-id="edit-permission"
        header-bg="bg-info"
        confirm-btn="bg-info text-light"
        confirm-text="تعديل"
        @confirmed="updatePermission">
        <h6>هل أنت متأكد من تعديل صلاحية&nbsp;<span class="link danger">{{ !!permission ? permission.text : '' }}</span>&nbsp;؟</h6>
        <input type="hidden" id="status-value" value="">
      </confirm-model>
    </teleport>
  </div>
</template>

<script>
import ConfirmModel from "@/components/UI/ConfirmModel";
import PermissionRow from "@/components/Admin/PermissionRow";

export default {
  components: {PermissionRow, ConfirmModel},
  props: {
    assistantId: {required: true}
  },
  data() {
    return {
      permissions: [],
      permission: '',
    };
  },
  computed: {
    hasPermissions() {
      return !!this.permissions && this.permissions.length > 0;
    }
  },
  methods: {
    async setPermissions() {
      if (!this.assistantId)
        return false;
      const response = await http.send('permissions', {_method: 'GET', user: this.assistantId});
      if (http.responseAccepted(response, 'permissions'))
        this.permissions = response.data.permissions;
    },
    async updatePermission() {
      if (!this.permission || !this.assistantId)
        return false;
      const response = await http.send('permissions', {_method: 'PUT', permission: this.permission.id, user: this.assistantId})
      if (http.responseAccepted(response, 'permissions')) {
        this.permissions = response.data.permissions;
        this.permission = '';
        this.hideModal('#edit-permission');
        http.popupMessage('success', messages.updated);
      }
    },
    async selectPermission(id) {
      if (!id || !this.hasPermissions)
        return false;
      this.permission = this.permissions.find(permission => permission.id === id);
      if (!!this.permission) {
        this.showModal('#edit-permission');
      }
    }
  },
  async created() {
    await this.setPermissions();
  }
}
</script>

<style scoped>
button {
  min-width: 60px;
}
</style>