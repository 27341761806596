<template>
  <tr v-if="!!permission">
    <td>{{ permission.text }}</td>
    <td>
      <button :id="permission.id" @click="selectPermission" class="btn btn-sm nr-shadow" :class="button">
        {{ text }}
      </button>
    </td>
  </tr>
</template>

<script>
import types from "@/common/types";

export default {
  props: {permission: {required: true}},
  emits: ['selected'],
  computed: {
    valid() {
      return !!this.permission && types.checkY(this.permission.value)
    },
    button() {
      return this.valid ? 'btn-danger' : 'btn-success'
    },
    text() {
      return this.valid ? 'إلغاء' : 'تمكين'
    }
  },
  methods: {
    selectPermission(e) {
      return this.$emit('selected', e.target.id)
    }
  }
}
</script>

<style scoped>
button {
  min-width: 60px;
}
</style>