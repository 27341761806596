<template>
  <div class="container-fluid pm-0">
    <div class="row">
      <div class="col-sm-12">
        <small
          v-if="hasTwoMobiles"
          class="text-danger text-left font-weight-bolder"
          >لا يمكن إضافة اكثر من رقمين</small
        >
        <modal-btn v-else target="#add-mobile" btn-class="pm-0 float-left">
          <label class="btn btn-sm btn-info nr-shadow">
            إضافة
            <i class="fa fa-plus"></i>
          </label>
        </modal-btn>
        <p class="link" v-if="hasNoMobiles">لا يوجد أرقام هواتف</p>
        <div v-else class="w-100 pm-0 table-responsive">
          <table class="table table-bordered">
            <tr>
              <th>الهاتف</th>
              <th>النوع</th>
              <th>تعديل</th>
            </tr>
            <mobile-row
              v-for="mobile in mobiles"
              :key="mobile.id"
              :mobile="mobile"
              @set-primary="setPrimary"
              @updated="updated"
              @deleted="deleted"
            ></mobile-row>
          </table>
        </div>
      </div>
    </div>
    <teleport to="#app" v-if="!hasTwoMobiles">
      <confirm-model
        title="إضافة هاتف"
        target-id="add-mobile"
        confirm-text="إضافة"
        @confirmed="clickById('store-mobile')"
      >
        <form @submit.prevent="store">
          <small class="link">
            <abbr :title="validMobilePattern">يرجى إضافة رقم هاتف صالح</abbr>
          </small>
          <form-input id="mobile" placeholder="رقم الهاتف"></form-input>
          <button id="store-mobile" hidden></button>
        </form>
      </confirm-model>
    </teleport>
  </div>
</template>

<script>
import ConfirmModel from "@/components/UI/ConfirmModel";
import FormInput from "@/components/Forms/FormInput";
import MobileRow from "@/components/Users/Mobile/MobileRow";
import ModalBtn from "@/components/UI/ModalBtn";
import arrays from "@/common/arrays";
import Storage from "@/common/Storage";
import types from "@/common/types";

export default {
  components: { ModalBtn, MobileRow, FormInput, ConfirmModel },
  // this component is to update user`s mobiles
  // the maximum number of mobiles is 2
  // the mobile has to keys 1- primary ? 2- verified ?
  // user should has at most on primary mobile
  emits: ["mobiles-changed"],
  props: {
    userId: { required: true },
  },
  data() {
    return {
      mobiles: [],
    };
  },
  computed: {
    hasNoMobiles() {
      return !this.mobiles || this.mobiles.length === 0;
    },
    hasTwoMobiles() {
      return !this.mobiles || this.mobiles.length >= 2;
    },
    validMobilePattern() {
      return messages.validMobilePattern;
    },
    storageName() {
      return `user-mobiles-${this.userId}`;
    },
  },
  methods: {
    async store(e) {
      if (!this.userId) {
        return http.popupMessage("error", "يرجى تحديد المستخدم");
      }

      const formData = new FormData(e.target);
      formData.set("user", this.userId);

      const response = await http.send("/mobiles", formData);
      if (http.responseAccepted(response)) {
        this.mobiles.push(response.data.mobile);
        this.setData(this.mobiles);

        this.hideModal("#add-mobile");
        http.popupMessage("success", messages.saved);
        $_("#mobile").val("");
      }
    },
    async setPrimary(id) {
      if (!this.userId) {
        return http.popupMessage("error", "يرجى تحديد المستخدم");
        1;
      }
      const response = await http.send("/mobile/primary", { mobile: id });
      if (http.responseAccepted(response)) {
        this.setData(response.data.mobiles);

        http.popupMessage("success", messages.updated);
        this.$emit("mobiles-changed");
      }
    },
    async getMobiles() {
      const mobiles = Storage.get(this.storageName);
      if (!!mobiles) {
        return this.setData(mobiles.data);
      }

      // GET THE MOBILES
      if (!this.userId) {
        return http.popupMessage("error", "يرجى تحديد المستخدم");
      }

      const response = await http.send("/mobiles", {
        user: this.userId,
        _method: "GET",
      });
      if (http.responseAccepted(response)) {
        this.setData(response.data.mobiles);
      }
    },
    deleted(id) {
      this.setData(arrays.removeById(this.mobiles, id));
    },
    async updated(updatedMobile) {
      await this.setData(arrays.updateOrPush(this.mobiles, updatedMobile));
      if (!!updatedMobile && types.checkY(updatedMobile.primary)) {
        if (!types.checkY(updatedMobile.verified))
          http.popupMessage("info", "يرجى تأكيد رقم الهاتف");
        this.$emit("mobiles-changed");
      }
    },
    setData(mobiles) {
      Storage.removeStartWith("user-mobiles");
      if (!mobiles) return;
      this.mobiles = types.objectToArray(mobiles);
      Storage.set(this.storageName, this.mobiles, 10);
    },
  },
  async created() {
    await this.getMobiles();
  },
};
</script>
